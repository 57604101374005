import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { GridComponent } from './grid/grid.component';
import { LazyImageDirective } from './lazy-image/lazy-image.directive';
import { InfiniteScrollDirective } from './infinite-scroll/infinite-scroll.directive';
import { GalleryItemComponent } from './gallery-item/gallery-item.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FIREBASE_CONFIG } from './app.const';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { getAnalytics, provideAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { HammerConfig } from './hammer.config';

@NgModule({
  declarations: [
    AppComponent,
    GridComponent,
    InfiniteScrollDirective,
    LazyImageDirective,
    GalleryItemComponent,
    LandingPageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    HammerModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(FIREBASE_CONFIG)),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
