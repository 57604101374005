import { FirebaseOptions } from 'firebase/app';
import { InjectionToken } from '@angular/core';

export const GRID_IMAGE_HEIGHT = 450;
export const GALLERY_IMAGE_HEIGHT = 1250;

export const MAX_ITEMS_PER_PAGE = 20;

export enum KeyCode {
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft',
  ESCAPE = 'Escape',
  UP_ARROW = 'ArrowUp'
}

// todo(florian) put this into a secret
export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: 'AIzaSyCvCfs73_WV7ep5MeXMTjwQMDLlINK8xds',
  authDomain: 'floriantopf-lp.firebaseapp.com',
  projectId: 'floriantopf-lp',
  storageBucket: 'floriantopf-lp.appspot.com',
  appId: '1:956029495554:web:710021a5f02b68b4eaba1b',
  measurementId: 'G-JF8QNT7YVY',
};

export const BUCKET_BASE_PATH = '/portfolio';

export const REGISTRY_PATH = '/assets/images-data.json';
export const REGISTRY_PATH_COLOR = '/assets/images-data-color.json'

export const GALLERY_URL_EXTENSION = '.html';

export const IMG_SRC_URL = `https://storage.googleapis.com/floriantopf-lp.appspot.com${BUCKET_BASE_PATH}`;

export const REGISTRY_PATH_TOKEN: InjectionToken<string> = new InjectionToken('REGISTRY_PATH');
export const GRID_TYPE_TOKEN: InjectionToken<string> = new InjectionToken('GRID_TYPE');

export enum GridType {
  monochrome = 'monochrom',
  color = 'farbe',
}
