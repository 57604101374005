import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BASE_IMAGE_URL, BASE_TITLE, BASE_URL, ThemeColor } from './metadata.const';
import { Image } from '../interfaces';
import { GALLERY_IMAGE_HEIGHT, GRID_IMAGE_HEIGHT, GridType } from '../app.const';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(private readonly metaService: Meta, private readonly titleService: Title) {}

  setGalleryImage(gridType: GridType, image: Image, urlParts: string[]) {
    // note(florian) atm the file name is used (which has dashes)
    const imageTitle = image.title
      .split('-')
      .join(' ')
      .toLowerCase();
    const fullTitle = `${BASE_TITLE} | ${gridType} | ${imageTitle}`;
    // note(florian) atm we use the grid item url as gallery url
    const galleryPath = urlParts.join('/').replace(`${GALLERY_IMAGE_HEIGHT}`, `${GRID_IMAGE_HEIGHT}`);
    const galleryUrl = `${BASE_URL}${galleryPath}`;
    const imageUrl = image.url.replace(`${GALLERY_IMAGE_HEIGHT}`, `${GRID_IMAGE_HEIGHT}`);

    this.setBaseTags(fullTitle, galleryUrl, imageUrl);
    this.setThemeColor(ThemeColor.black);
  }

  setGrid(gridType: GridType) {
    this.setBaseTags(`${BASE_TITLE} | ${gridType}`, `${BASE_URL}${gridType}/`)
    this.setThemeColor(ThemeColor.white);
  }

  setLandingPage() {
    this.titleService.setTitle(BASE_TITLE);
    this.setThemeColor(ThemeColor.black);
    this.setBaseTags(BASE_TITLE, BASE_URL)
  }

  private setThemeColor(color: ThemeColor) {
    this.metaService.updateTag({name: 'theme-color', content: color});
  }

  private setBaseTags(title: string, url: string, image: string = BASE_IMAGE_URL) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({property: 'og:title', content: title})
    this.metaService.updateTag({property: 'og:url', content: url});
    this.metaService.updateTag({property: 'og:image', content: image});
  }
}
