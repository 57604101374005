import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { GalleryUrlSerializer } from './gallery-url/gallery-url.serializer';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { GridType } from './app.const';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: GridType.monochrome,
    loadChildren: () => import('./grid/monochrome-grid.module').then(m => m.MonochromeGridModule),
  },
  {
    path: GridType.color,
    loadChildren: () => import('./grid/color-grid.module').then(m => m.ColorGridModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: UrlSerializer,
      useClass: GalleryUrlSerializer,
    },
  ],
})
export class AppRoutingModule {
}
