import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridType } from '../app.const';
import { MetadataService } from '../metadata/metadata.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  public gridTypes: typeof GridType = GridType;

  constructor(
    private readonly metadataService: MetadataService,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.metadataService.setLandingPage()
  }

  public navigateToGrid (gridType: GridType) {
    this.router.navigate([gridType]);
  }
}
